@use "@angular/material"as mat;
@use "sass:map";
@use '../nista-mixins' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }
  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .tag-root {
    @include mat.typography-level($typography, "Desktop/Oxygen/sm/Bold");
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $greyscale: map.get($config, "greyscale");
  $uiprimary: map.get($config, "accent");
  $info: map.get($config, "info");
  $success: map.get($config, "success");

  .tag-root {
    &.info-blue {
      background-color: mat.get-color-from-palette($info, 150);
      color: mat.get-color-from-palette($uiprimary, 850);
    }

    &.primary-blue {
      background-color: mat.get-color-from-palette($uiprimary, 150);
      color: mat.get-color-from-palette($uiprimary, 700);
    }

    &.green {
      background-color: mat.get-color-from-palette($success, 150);
      color: mat.get-color-from-palette($success, 800);
    }

    &.purple {
      background-color: #e5d1ff;
      color: #532889;
    }

    &.grey {
      background-color: mat.get-color-from-palette($greyscale, 150);
      color: mat.get-color-from-palette($greyscale, 850);
    }

    &.outline {
      background-color: transparent;
      box-shadow: 0 0 0 1px inset mat.get-color-from-palette($greyscale, 400);
      color: mat.get-color-from-palette($greyscale, 400);
    }
  }
}
