@use "@angular/material" as mat;
@use "sass:map";
@use "../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $typography: mat.get-typography-config($theme);

  @if $typography !=null {
    @include typography($typography);
  }

  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @font-face {
    font-family: "Aeroport";
    src: local("Aeroport"),
      url(../assets/fonts/Aeroport/Aeroport-regular.woff2) format("woff2");
  }

  @font-face {
    font-family: "Aeroport-Bold";
    src: local("Aeroport"),
      url(../assets/fonts/Aeroport/Aeroport-bold.woff2) format("woff2");
    font-weight: bold;
  }

  @font-face {
    font-family: "Aeroport-Light";
    src: local("Aeroport"),
      url(../assets/fonts/Aeroport/Aeroport-light.woff2) format("woff2");
    font-weight: lighter;
  }

  @font-face {
    font-family: "Oxygen-Mono";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../assets/fonts/Oxygen/OxygenMono-Regular.woff2) format("woff2");
  }

  /* latin-ext */
  @font-face {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../assets/fonts/Oxygen/2sDcZG1Wl4LcnbuCJW8zZmW5O7w.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../assets/fonts/Oxygen/2sDcZG1Wl4LcnbuCJW8zaGW5.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  /* latin-ext */
  @font-face {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../assets/fonts/Oxygen/2sDfZG1Wl4LcnbuKgE0mV0Q.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../assets/fonts/Oxygen/2sDfZG1Wl4LcnbuKjk0m.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  /* latin-ext */
  @font-face {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../assets/fonts/Oxygen/2sDcZG1Wl4LcnbuCNWgzZmW5O7w.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../assets/fonts/Oxygen/2sDcZG1Wl4LcnbuCNWgzaGW5.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  /* fallback */
  @font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/Material/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
      format("woff2");
  }

  /* fallback */
  @font-face {
    font-family: "Material Icons Outlined";
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/Material/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
      format("woff2");
  }

  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    -moz-font-feature-settings: "liga";
    -moz-osx-font-smoothing: grayscale;
  }

  .material-icons-outlined {
    font-family: "Material Icons Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    -moz-font-feature-settings: "liga";
    -moz-osx-font-smoothing: grayscale;
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $background: map.get($config, "background");
  $foreground: map.get($config, "foreground");
  $greyscale: map.get($config, "greyscale");
  $error: map.get($config, "error");
  $success: map.get($config, "success");
  $transparent: map.get($config, "transparency");

  body {
    fill: white;
  }

  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: mat.get-color-from-palette($greyscale, A100) !important;
  }

  .mat-checkbox-indeterminate.mat-greyscale .mat-checkbox-background,
  .mat-checkbox-checked.mat-greyscale .mat-checkbox-background {
    background-color: mat.get-color-from-palette($greyscale, 700) !important;
  }

  .mat-greyscale {
    .mat-checkbox-inner-container:hover {
      .mat-checkbox-persistent-ripple {
        opacity: 0;
      }

      .mat-checkbox-frame {
        border-color: mat.get-color-from-palette($greyscale, 200) !important;
      }
    }
    .mat-checkbox-background,
    .mat-checkbox-frame {
      border-radius: 100%;
    }

    .mat-checkbox-frame {
      border-color: mat.get-color-from-palette($greyscale, 400) !important;
    }

    &.mat-checkbox-disabled {
      .mat-checkbox-inner-container:hover,
      .mat-checkbox-inner-container {
        .mat-checkbox-frame {
          border-color: mat.get-color-from-palette($greyscale, 600) !important;
        }
      }
    }
  }

  .mat-stroked-button,
  .mat-raised-button,
  .mat-button-toggle-standalone,
  .mat-button {
    border-radius: unset !important;
  }

  .mat-raised-button.mat-primary.mat-button-disabled {
    color: mat.get-color-from-palette($greyscale, A100);
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-20"
    );
  }

  .mat-button-toggle-label-content {
    line-height: 38px !important;
  }

  .mat-button-toggle-standalone {
    border: 0px !important;
  }

  a:not(.nista-button) {
    color: mat.get-color-from-palette($greyscale, A100) !important;
    text-decoration: none !important;
    font-weight: bold;

    &:link,
    &:active,
    &:visited {
      color: mat.get-color-from-palette($greyscale, A100) !important;
      text-decoration: none !important;
      font-weight: bold;
    }

    &:hover {
      color: mat.get-color-from-palette($greyscale, A100) !important;
      text-decoration: underline !important;
    }
  }

  .info {
    color: mat.get-color-from-palette($greyscale, 700);
  }

  .warn {
    color: mat.get-color-from-palette($accent, "500-contrast");
    background-color: mat.get-color-from-palette($accent, 500);
  }

  .xlarge-text {
    font-size: x-large;
    font-family: "Aeroport";
  }

  .small-button {
    color: mat.get-color-from-palette($greyscale, 300);
    fill: mat.get-color-from-palette($greyscale, 300);

    > * svg {
      fill: mat.get-color-from-palette($greyscale, 300);
    }

    cursor: pointer;

    align-self: center;
    line-height: 10px;

    &.active {
      color: mat.get-color-from-palette($primary, 500);
    }

    &:hover {
      color: mat.get-color-from-palette($greyscale, A100);
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-20"
      );
      fill: mat.get-color-from-palette($greyscale, A100);
    }

    &-dark {
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-5"
      );
    }
  }

  .close-icon {
    color: mat.get-color-from-palette($greyscale, A100);
    font-size: x-large;
    font-weight: bold;
  }

  .close-button {
    border: 0 !important;
    border-radius: 0 !important;
    cursor: pointer;
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-10"
    );

    &.active {
      color: mat.get-color-from-palette($primary, 500);
    }

    &:hover {
      color: mat.get-color-from-palette($greyscale, A100);
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-20"
      );
      fill: mat.get-color-from-palette($greyscale, A100);
    }
  }

  app-gnista-icon,
  .app-gnista-icon {
    * > .icon {
      background-color: mat.get-color-from-palette($greyscale, A100);
    }
  }

  .info {
    @include nista.icon-color(mat.get-color-from-palette($greyscale, 700));
  }

  .warn {
    @include nista.icon-color(mat.get-color-from-palette($accent, 500));
  }

  .error {
    @include nista.icon-color(mat.get-color-from-palette($error, 500));
  }

  .error-box {
    background-color: mat.get-color-from-palette($warn, 100);
    border-radius: 10px;

    @include nista.icon-color(mat.get-color-from-palette($error, 500));

    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .mat-snack-bar-container {
    border-radius: 0 !important;
  }

  .mat-toolbar.mat-accent {
    color: mat.get-color-from-palette($greyscale, A100) !important;
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-10"
    ) !important;
  }

  .mat-simple-snackbar-action {
    color: mat.get-color-from-palette($greyscale, A100) !important;
    border-left: 0.7px solid
      mat.get-color-from-palette($background, "dark-bg-lighter-20") !important;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    border-radius: 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: white !important;
  }

  .mat-chip.mat-standard-chip {
    background-color: mat.get-color-from-palette($accent, 500);
    &.readOnly:hover::after,
    &.readOnly:focus::after {
      opacity: 0;
    }
  }

  .ng-select.custom-gnista-select {
    .ng-select-container {
      min-height: 0px;
      border-radius: 0;
      border: 1px solid mat.get-color-from-palette($greyscale, 700);
      padding: 3px;
    }
    .ng-option {
      padding: 3px;
      background-color: mat.get-color-from-palette($greyscale, 800);
      &:hover {
        background-color: mat.get-color-from-palette($greyscale, 700);
      }
    }
    .ng-arrow {
      border-color: mat.get-color-from-palette(
          $background,
          "dark-bg-lighter-30"
        )
        transparent transparent;
    }
  }

  .ng-select-opened.custom-gnista-select {
    .ng-arrow {
      border-color: transparent transparent
        mat.get-color-from-palette($background, "dark-bg-lighter-30");
    }
  }

  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.7);
  }

  .sideBarLabel {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    color: mat.get-color-from-palette($greyscale, 300);
    margin-bottom: 1px;
  }

  .sideBarInput {
    border: 0.7px solid mat.get-color-from-palette($greyscale, 700);

    &:hover {
      border: 0.7px solid mat.get-color-from-palette($greyscale, A100);
    }
  }

  .floatingSuggestions {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    );
  }

  ::placeholder {
    color: mat.get-color-from-palette($greyscale, 700) !important;
    opacity: 1;
  }

  ::-ms-input-placeholder {
    color: mat.get-color-from-palette($greyscale, 700) !important;
  }

  .scroll-thumb {
    background-color: mat.get-color-from-palette($greyscale, 300) !important;
  }

  .scroll-bar {
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    ) !important;
  }

  app-connection-lost-overlay {
    .container {
      svg {
        fill: mat.get-color-from-palette($greyscale, A100) !important;
      }

      color: mat.get-color-from-palette($greyscale, A100) !important;
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-10"
      );
    }

    .darken-overlay {
      opacity: 0.5;
      background-color: black;
    }
  }

  .cdk-overlay-pane {
    margin-top: 4px;
    padding: 1px;
    border-radius: 5px;
  }

  .mat-tooltip {
    margin: 6px !important;
  }

  .mat-menu-panel {
    border-radius: 4px;
    box-shadow: 0 0 0 1px mat.get-color-from-palette($greyscale, 600),
      0px 12px 12px 0px rgba(0, 0, 0, 0.25);
    background-color: mat.get-color-from-palette(
      $background,
      "bg-container-popupmenu"
    );

    .mat-menu-item.border-top-item {
      border-top: 1px solid;
      border-color: mat.get-color-from-palette($foreground, "container-border");
    }
  }

  .mat-menu-content {
    padding: 0 !important;
    background-color: mat.get-color-from-palette(
      $background,
      "bg-container-popupmenu"
    ) !important;
  }

  .mat-menu-panel.user-menu {
    margin-right: 12px;
    min-width: 268px;
    margin-top: 4px;
  }

  .grey50 {
    color: mat.get-color-from-palette($greyscale, 50);
  }

  .grey150 {
    color: mat.get-color-from-palette($greyscale, 150);
  }

  .grey200 {
    color: mat.get-color-from-palette($greyscale, 200);
  }

  .dimmed {
    color: mat.get-color-from-palette($greyscale, 300);

    * svg {
      fill: mat.get-color-from-palette($greyscale, 300) !important;
    }
  }

  .dimmed400 {
    color: mat.get-color-from-palette($greyscale, 400);

    * svg {
      fill: mat.get-color-from-palette($greyscale, 400) !important;
    }
  }

  .welcome-popup {
    .mat-dialog-container {
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-5"
      );
      textarea {
        &::placeholder {
          color: mat.get-color-from-palette($greyscale, 300) !important;
        }
      }
    }
    .text {
      h2 {
        font-weight: 700;
        font-size: 16px;
        color: mat.get-color-from-palette($greyscale, 50) !important;
      }
      .body {
        font-size: 14px;
        color: mat.get-color-from-palette($greyscale, 200) !important;
      }
    }
    .close {
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-20"
      );
      opacity: 50%;
    }
    .mat-dialog-container {
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
    }
    .tab {
      &.active {
        background-color: mat.get-color-from-palette($greyscale, A100);
      }

      &.inactive {
        background-color: mat.get-color-from-palette($greyscale, 800);
      }
    }
  }

  .bordered-800 {
    @include nista.box-border(mat.get-color-from-palette($greyscale, 800));
  }

  .nista-dialog {
    border-radius: 0px;
    box-shadow: none;
    .dialog-body {
      background-color: mat.get-color-from-palette($greyscale, 850);
    }

    .formlabel {
      color: mat.get-color-from-palette($greyscale, 100);
    }

    .forminput {
      @include nista.box-border(mat.get-color-from-palette($greyscale, 500));
      color: mat.get-color-from-palette($greyscale, 300);
    }

    .mat-dialog-container {
      padding: 0px;
      border-radius: 0px;
      box-shadow: none;
    }
  }

  .wordmark {
    fill: mat.get-color-from-palette($greyscale, 300);

    &:hover {
      fill: mat.get-color-from-palette($primary);
    }
  }

  .dialog-separator {
    border-bottom-color: mat.get-color-from-palette($background, "background");
  }

  .inputWrapper {
    border-color: mat.get-color-from-palette($greyscale, 700);
  }
  .inputWrapper:hover {
    border-color: mat.get-color-from-palette($greyscale, A100);
  }
  .delete-button {
    background-color: mat.get-color-from-palette($error, 700);
    color: mat.get-color-from-palette($error, "700-contrast");
  }
  .welcome-window {
    .gifbox {
      background-color: mat.get-color-from-palette($greyscale, 950);
    }
  }

  .nista-error-box {
    box-shadow: 0 0 0 1px inset mat.get-color-from-palette($error, 800);
    background-color: mat.get-color-from-palette($error, 950);
    .nista-error-box-headline,
    .nista-error-box-close {
      color: mat.get-color-from-palette($error, 500);
      font-family: "Oxygen";
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.03em;
      font-size: 12px;
      line-height: 16px;
    }

    .nista-error-box-text {
      color: mat.get-color-from-palette($error, 50);
      font-family: "Oxygen";
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.03em;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .gnista-drop-zone {
    &.ngx-file-drop__drop-zone--over {
      .gnista-content-zone > .drop-zone-container > .drop-zone-background {
        background-color: mat.get-color-from-palette($accent, 500);
      }
      .gnista-content-zone > .drop-zone-container {
        border-color: mat.get-color-from-palette($accent, 500);
      }
    }
  }

  .expander-root {
    background-color: mat.get-color-from-palette($greyscale, 850);
  }

  .chartMarker {
    position: absolute;
    pointer-events: none;
    display: none;
    background-color: rgba(mat.get-color-from-palette($accent, 500), 0.4);
    border: 2px solid mat.get-color-from-palette($accent, 500);
  }

  .scrollOverlay {
    background-color: rgba(mat.get-color-from-palette($greyscale, 700), 0.7);
    z-index: 1001;
  }

  .area {
    .marker {
      background-color: rgba(mat.get-color-from-palette($accent, 500), 0.1);
      border: 2px solid mat.get-color-from-palette($accent, 500);
      z-index: 900;
    }

    .badge {
      z-index: 900;
      background-color: white;
      padding: 3px;
      border-radius: 0px;

      * {
        z-index: 900;
      }
    }

    .badge {
      &.bottom-left {
        &::before {
          display: block;
          content: "";
          position: absolute;
          bottom: 0px;
          left: -3px;
          width: 6px;
          height: 6px;
          z-index: 899;
          background-color: white;
          transform-origin: center;
          transform: skew(-45deg);
        }
      }
      &.bottom-right {
        &::before {
          display: block;
          content: "";
          position: absolute;
          bottom: 0px;
          right: -3px;
          width: 6px;
          height: 6px;
          z-index: 899;
          background-color: white;
          transform-origin: center;
          transform: skew(45deg);
        }
      }
    }
  }

  .upgrade-banner {
    background-color: mat.get-color-from-palette($greyscale, 850);
    color: mat.get-color-from-palette($greyscale, 150);
    .nista {
      color: mat.get-color-from-palette($primary, 500);
      i {
        background-color: mat.get-color-from-palette($primary, 500);
      }
    }
  }

  .upgrade-card {
    background-color: mat.get-color-from-palette($greyscale, 850);
    color: mat.get-color-from-palette($greyscale, 100);
    .nista {
      color: mat.get-color-from-palette($primary, 500);
      i {
        background-color: mat.get-color-from-palette($primary, 500);
      }
    }
  }
  .default-back {
    background-color: #131313;
  }

  .progress-container {
    .subbar {
      background-color: mat.get-color-from-palette($greyscale, 700);
    }
    .progress {
      background-color: mat.get-color-from-palette($primary, 600);
    }
    .avatar {
      background-color: mat.get-color-from-palette($primary, 500);
    }
  }

  app-report-facility-overview-content {
    .arrow {
      &-down {
        background-color: mat.get-color-from-palette($success, 850);
        app-gnista-icon i {
          background-color: mat.get-color-from-palette($success, 200);
        }
      }
      &-up {
        background-color: mat.get-color-from-palette($error, 700);
        app-gnista-icon i {
          background-color: mat.get-color-from-palette($error, 300);
        }
      }
    }
  }

  .dark-box {
    background-color: mat.get-color-from-palette($greyscale, 1000);
    color: mat.get-color-from-palette($greyscale, 100);
    border: 1px solid mat.get-color-from-palette($greyscale, 700);
    box-shadow: 0px 4px 4px 0px #00000040;
    &:hover {
      border: 1px solid mat.get-color-from-palette($greyscale, 50);
    }
  }

  .nista-box {
    color: mat.get-color-from-palette($greyscale, 100);

    h2,
    h3 {
      color: mat.get-color-from-palette($greyscale, 200);
    }

    .section {
      background-color: mat.get-color-from-palette($background, bg-container-2);
      border: 1px solid mat.get-color-from-palette($greyscale, 800);
      border-radius: 2px;
    }

    .section-separation-top {
      @include nista.box-border-top(
        mat.get-color-from-palette($greyscale, 800)
      );
    }

    .section-separation-bottom {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($greyscale, 800)
      );
    }

    .section-separation-bottom:last-of-type {
      @include nista.box-border-bottom(
        mat.get-color-from-palette($transparent, 5)
      );
    }

    .section-header {
      p {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }
  }

  .rounded-box {
    border-radius: 4px;
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .container {
      max-width: 500px !important;
      &.narrow {
        max-width: 400px !important;
      }
    }
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (min-width: 768px) {
    .container {
      max-width: 680px !important;
      &.narrow {
        max-width: 500px !important;
      }
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .container {
      max-width: 800px !important;
      &.narrow {
        max-width: 600px !important;
      }
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .container {
      max-width: 970px !important;
      &.narrow {
        max-width: 800px !important;
      }
    }
  }

  /* Extra extra large devices (extra large desktops, 1400px and up) */
  @media (min-width: 1400px) {
    .container {
      max-width: 1320px !important;
      &.narrow {
        max-width: 912px !important;
      }
    }
  }
}

@mixin typography($typography) {
}
