@use "@angular/material" as mat;
@use "sass:map";
@use "../nista-mixins.scss" as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  app-bar-chart-graph-content,
  app-report-line-graph-content,
  app-report-week-comparison-content,
  app-report-number-comparison-content {
    .title {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
    }
  }
  app-report-line-graph-content {
    .time-range {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }
  }
  app-canvasjs-bar-chart,
  app-canvasjs-chart-new,
  app-weekperiod-graph-alt {
    .axis-label {
      @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
    }
  }

  .summary {
    @include mat.typography-level($typography, "Desktop/Aeroport/xl/Regular");
  }

  .open_text {
    @include mat.typography-level($typography, "Desktop/Oxygen/sm/Regular");
  }

  .ammount {
    @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
  }

  app-report-seriesdisplay {
    .display-container {
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
    }
  }
  app-report-footer-seriesdisplay {
    .lastUpdated {
      span {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
    }
  }
  app-report-yearly-stats-content {
    .saving {
      .title {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
      .content {
        .money{
          @include mat.typography-level($typography, "Desktop/Aeroport/xl/Regular");
        }
        .energy {
          @include mat.typography-level($typography, "Desktop/Aeroport/md/Regular");
        }
        .footprint {
          @include mat.typography-level($typography, "Desktop/Aeroport/md/Regular");
        }
      }
    }
    .bottom {
      .bar {
        @include mat.typography-level($typography, "Desktop/Aeroport/xl/Regular");
        font-weight: 700 !important;
      }
      .label {
        @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
      }
      .date {
        @include mat.typography-level($typography, "Desktop/Aeroport/md/Regular");
      }
    }
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $greyscale: map.get($config, "greyscale");
  $accent: map.get($config, accent);
  $success: map.get($config, "success");
  $error: map.get($config, "error");
  $secondary: map.get($config, "secondary");
  $transparency: map.get($config, "transparency");
  $info: map.get($config, "info");
  $warn: map.get($config, warn);

  app-bar-chart-graph-content,
  app-report-line-graph-content,
  app-report-week-comparison-content,
  app-report-number-comparison-content {
    .title {
      color: mat.get-color-from-palette($greyscale, 50);
    }
  }
  app-report-line-graph-content {
    .time-range {
      color: mat.get-color-from-palette($greyscale, 100);
    }
  }
  app-canvasjs-bar-chart,
  app-canvasjs-chart-new,
  app-weekperiod-graph-alt {
    .axis-label {
      color: mat.get-color-from-palette($greyscale, 300);
    }
  }
  .report-table {
    app-gnista-icon {
      fill: mat.get-color-from-palette($greyscale, 500);
    }

    .undefined-cell {
      color: mat.get-color-from-palette($greyscale, 400);
    }

    h3 {
      color: mat.get-color-from-palette($greyscale, 100);
    }
  }
  .above-report-table {
    @include nista.box-border(
      mat.get-color-from-palette($transparency, 40)
    );
  }

  .report-card,
  .template-card {
    .image {
      border: 1px solid;
      border-color: mat.get-color-from-palette($greyscale, 700);
    }

    :hover {
      .image {
        border-color: mat.get-color-from-palette($greyscale, A100);
      }

      .report-delete,
      .template-delete {
        display: block;
      }
    }
  }

  .report-download {
    background-color: mat.get-color-from-palette($greyscale, 850);

    button {
      background-color: mat.get-color-from-palette($accent, 500);
    }

    button:disabled {
      background-color: mat.get-color-from-palette($greyscale, 700);
    }
  }

  .report-delete,
  .template-delete {
    :hover {
      fill: mat.get-color-from-palette($greyscale, A100);
    }

    fill: mat.get-color-from-palette($greyscale, 300);
  }

  app-report-facility-overview-content {
    .facility-table {
      th {
        color: mat.get-color-from-palette($greyscale, 200);
        background-color: mat.get-color-from-palette($greyscale, 800);
      }
      td {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }
  }

  app-report-line-graph-content,
  app-report-heatmap-content,
  app-report-preview-heatmap-content,
  app-report-preview-line-graph-content,
  app-report-html-content,
  app-report-text-content,
  app-report-table-content,
  app-bar-chart-graph-content,
  app-report-insights-summary-content,
  app-report-preview-image-content,
  app-report-personal-tasks-content,
  app-report-week-comparison-content,
  app-report-number-comparison-content,
  app-report-multiaxiscomparison-content,
  app-report-yearly-stats-content,
  app-report-facility-hierarchy {
    height: 100%;
    display: block;
    padding: 16px;
    min-height: 350px;
    background-color: mat.get-color-from-palette($greyscale, 1000);
    border-color: mat.get-color-from-palette($greyscale, 800);
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 6px 6px 0px #00000040;
    border-radius: 2px;
  }

  app-report-table-content {
    min-height: 90px;
  }

  app-report-yearly-stats-content {
    .bottom-separator {
      @include nista.box-border-bottom(
          mat.get-color-from-palette($greyscale, 400)
        );
    }
    .center {
      .realised-saving {
        @include nista.box-border(
            mat.get-color-from-palette($success, 500)
          );
        .dot {
          background-color: mat.get-color-from-palette($success, 500);
        }
      }
      .planned-saving {
        @include nista.box-border(
            mat.get-color-from-palette($info, 500)
          );
        .dot {
          background-color: mat.get-color-from-palette($info, 500);
        }
      }
      .open-saving {
        @include nista.box-border(
            mat.get-color-from-palette($warn, 500)
          );
        .dot {
          background-color: mat.get-color-from-palette($warn, 500);
        }
      }
      .missing-saving {
        @include nista.box-border(
            mat.get-color-from-palette($primary, 500)
          );
        .dot {
          background-color: mat.get-color-from-palette($primary, 500);
        }
      }
    }
    .bottom {
      .realised-saving {
        .bar {
          background-color: mat.get-color-from-palette($success, 500);
        }
        .start {
          @include nista.box-border-left(
            mat.get-color-from-palette($success, 500), "2px"
          );
        }
      }
      .planned-saving {
        .bar {
          background-color: mat.get-color-from-palette($info, 500);
        }
        .planned-finish {
          @include nista.box-border-right(
            mat.get-color-from-palette($info, 500), "2px"
          );
        }
      }
      .open-saving {
        .bar {
          background-color: mat.get-color-from-palette($warn, 500);
        }
      }
      .missing-saving {
        .bar {
          background-color: mat.get-color-from-palette($primary, 500);
        }
      }
    }

  }

  app-report-dashboard-savings {
    background-color: mat.get-color-from-palette($greyscale, 1000);
    border-color: mat.get-color-from-palette($greyscale, 800);
    border-style: solid;
    border-width: 1px;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.2509803922);
    border-radius: 2px;
  }

  app-report-dashboard-open-potentials,
  app-report-dashboard-open-initiatives {
    .open-initiative-widget,
    .open-potential-widget {
      height: 100%;
      display: block;
      background-color: mat.get-color-from-palette($greyscale, 1000);
      border-color: mat.get-color-from-palette($greyscale, 800);
      border-style: solid;
      border-width: 1px;
      box-shadow: 0px 6px 6px 0px #00000040;
      border-radius: 2px;
    }

    .spacer {
      border-color: mat.get-color-from-palette($greyscale, 800);
      border-left-style: solid;
      border-width: 1px;
    }

    .initiative-list-container {
      border-color: mat.get-color-from-palette($greyscale, 800);
      border-top-style: solid;
      border-width: 1px;
    }

    .initiative-box {
      background-color: mat.get-color-from-palette($greyscale, 850);
      border-color: mat.get-color-from-palette($greyscale, 700);
      border-style: solid;
      border-width: 1px;
      border-radius: 2px;
      &:hover {
        background-color: mat.get-color-from-palette($greyscale, 800);
      }
      &:focus {
        background-color: mat.get-color-from-palette($greyscale, 800);
        border-color: mat.get-color-from-palette($greyscale, 50);
      }
    }

    .dimmed {
      path {
        fill: mat.get-color-from-palette($greyscale, 300) !important;
      }
    }
  }

  app-report-subdashboard-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }

  app-report-subdashboard-content {
    height: 100%;
    display: block;
  }

  app-report-seriesdisplay {
    .display-container {
      background-color: mat.get-color-from-palette($greyscale, 850);
    }
  }
  app-report-footer-seriesdisplay {
    .lastUpdated {
      span {
        color: mat.get-color-from-palette($greyscale, 300);
      }
    }
  }
  app-bar-chart-graph-content,
  app-report-line-graph-content,
  app-report-week-comparison-content {
    .title {
      margin-bottom: 16px;
    }
  }

  app-report-personal-tasks-content,
  app-report-number-comparison-content {
    padding: 0px;
    .title {
      padding: 8px 12px;
    }
    .empty-container,
    .task-feed,
    .comparison-body {
      background-color: mat.get-color-from-palette($greyscale, 975);.title {

    }
      .description {
        color: mat.get-color-from-palette($greyscale, 500);
      }
    }
    .bar {
      &.baseline {
        background-color: mat.get-color-from-palette($greyscale, 700);
      }
    }
  }

  .clickable-widget {
    height: 100%;
    display: block;
    &:hover {
      cursor: pointer;
      transform: scale(1.01);
      transition: 200ms ease-out;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.16),
        0px 12px 12px 0px rgba(0, 0, 0, 0.25);
    }
  }
}
