@use '@angular/material'as mat;
@use 'sass:map';

@mixin theme($theme) {
  $color: mat.get-color-config($theme);

  @if $color !=null {
    @include color($color);
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $foreground: map.get($config, foreground);
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");
  

  .dateAndChart{
    background-color: mat.get-color-from-palette($background);
    border: 1px solid mat.get-color-from-palette($greyscale, 800);
  }

  .startDate{
    font-size: 16px;
    color: mat.get-color-from-palette($greyscale, 150);
  }
}