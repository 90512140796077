@use '@angular/material'as mat;
@use 'sass:map';
@use '../nista-mixins' as nista;

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}

@mixin typography($typography) {
  .header-bar {
    span {
      @include mat.typography-level($typography, "Desktop/Aeroport/md/Regular");
    }
  }
  #comments-sidebar {
    .no-comments {
      @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
    }
  }
  .user-info{
    .user-account{
      @include mat.typography-level($typography, "Desktop/Oxygen/md/Bold");
    }
    .user-details{
      .user-username_email{
        .user-username{
          @include mat.typography-level($typography, "Desktop/Oxygen/xl/Bold");
        }
        .user-email{
          @include mat.typography-level($typography, "Desktop/Oxygen/md/Regular");
        }
      }
    }
  }
  .border-top-item{
    @include mat.typography-level($typography, "Desktop/Oxygen/xl/Regular");
  }
}

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, "primary");
  $accent: map.get($config, "accent");
  $foreground: map.get($config, "foreground");
  $background: map.get($config, "background");
  $greyscale: map.get($config, "greyscale");

  #comments-sidebar {
    background-color: mat.get-color-from-palette($greyscale, 1000);
    .new-comment {
      background-color: mat.get-color-from-palette($greyscale, 850);
    }
    .no-comments {
      color: mat.get-color-from-palette($greyscale, 500);
    }
  }

  // Define any styles affected by the theme.
  .create-new-card {
    // Use mat-color to extract individual colors from a palette.
    border: 2px solid mat.get-color-from-palette($primary);
    box-sizing: border-box;
    color: mat.get-color-from-palette($greyscale, 300);

    &:hover {
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-5"
      );
      color: mat.get-color-from-palette($greyscale, A100);
    }
  }

  .existing-calculation-card,
  .existing-spark-card {
    // color: mat.get-color-from-palette($greyscale, 1000);
    background-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-20"
    );

    > .bottom {
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-10"
      );
      font-size: 14px;

      > .icon {
        app-gnista-icon {
          font-size: 18px;
        }

        color: mat.get-color-from-palette($greyscale, 300);

        &:hover {
          color: mat.get-color-from-palette($greyscale, A100);
        }
      }

      .lastUpdated {
        color: mat.get-color-from-palette($foreground, "secondary-text");
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .row .data-point,
  .row .data-source,
  .row .time-series-mask {
    border-bottom: 1px solid mat.get-color-from-palette($greyscale, 800);

    &.header {
      font-weight: bold;
      border-bottom: 1px solid mat.get-color-from-palette($greyscale, 800);
    }

    &:hover {
      &:not(.header) {
        background-color: mat.get-color-from-palette(
          $background,
          "dark-bg-lighter-5"
        );
        cursor: pointer;
      }
    }

    &.active {
      background-color: mat.get-color-from-palette(
        $background,
        "dark-bg-lighter-5"
      );
    }

    &:last-child {
      border-bottom: 1px solid mat.get-color-from-palette($greyscale, 800);
    }
  }

  .librarysidebar {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);

    .close {
      color: mat.get-color-from-palette($greyscale, 300);

      &:hover {
        color: mat.get-color-from-palette($greyscale, A100);
      }
    }
  }

  .header-button {
    border: mat.get-color-from-palette($greyscale, 700) 1px solid;

    &.selected {
      border: 1px solid mat.get-color-from-palette($greyscale, A100);
      background-color: mat.get-color-from-palette($greyscale, 800);
    }
  }

  .column-details {
    background-color: mat.get-color-from-palette($background);
    border: mat.get-color-from-palette($greyscale, 700) 1px solid;
    border-left-width: 3px;

    &.selected {
      background-color: mat.get-color-from-palette($greyscale, 800);
      border: mat.get-color-from-palette($greyscale, A100) 1px solid;
      border-left-width: 3px;
    }
  }

  .sticky-container {
    background-color: mat.get-color-from-palette($background, "background");
  }

  .teaser {
    font-size: x-small;
    font-weight: bolder;
    padding-top: 2px;
    padding-left: 13px;
    text-align: right;
  }

  .sideBarSection {
    border-bottom-color: mat.get-color-from-palette(
      $background,
      "dark-bg-lighter-5"
    );
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  textarea {
    font-family: "Oxygen";
  }

  .user-info{
    .user-account{
      color: mat.get-color-from-palette($greyscale, 100);
    }

    .user-details{
      .user-username_email{
        .user-username{
          color: mat.get-color-from-palette($greyscale, 100);
        }
        .user-email{
          color: mat.get-color-from-palette($greyscale, 200);
        }
      }
    }
  }

  .border-top-item{
    color: mat.get-color-from-palette($greyscale, 200);
    @include nista.icon-color(mat.get-color-from-palette($greyscale, 200));
  }

}
